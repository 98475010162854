import React, { useEffect, useState } from 'react';

import { Alert, Col, Row, Typography } from 'antd';
import { DocusignCredentialType } from 'types/docusign';
import { getDocusignAccountInformation } from 'utils/ez_api/docusignAPI';

export const DocusignIntegrationIndicator: React.FC<{}> = ({ ...props }) => {
    const [credential, setCredential] = useState<DocusignCredentialType>();

    const _fetchCredentials = async () => {
        getDocusignAccountInformation().then(resp => {
            setCredential(resp.data);
        });
    };

    useEffect(() => {
        _fetchCredentials();
    }, []);

    return (
        <>
            <Row gutter={[16, 0]}>
                {credential && credential.docusign_email ? (
                    <Col span={24} className="pull-left text-left">
                        <Alert
                            message={
                                <>
                                    You currently use&nbsp;
                                    <b> {credential?.docusign_email} </b>
                                    for sending your DocuSign Envelope(s).
                                </>
                            }
                            type="info"
                            showIcon
                            className="mb-3"
                        />
                    </Col>
                ) : credential && credential.parent_account_id ? (
                    <Col span={24} className="pull-left text-left">
                        <Alert
                            message={
                                <>
                                    Your 3rd-party DocuSign integration has expired. <br />
                                    Please go to the <b>Admin Area</b>, click{' '}
                                    <b>3rd-Party Integration</b> menu, and navigate to the{' '}
                                    <b>DocuSign</b> tab to reconnect the selected sender profile.
                                </>
                            }
                            type="error"
                            showIcon
                            className="mb-3"
                        />
                    </Col>
                ) : (
                    <Col span={24} className="pull-left text-left">
                        <Alert
                            message={
                                <>
                                    Your document will be securely sent through EZCoordinator's
                                    DocuSign account. You can integrate your own DocuSign account at
                                    any time in your settings.
                                </>
                            }
                            type="info"
                            showIcon
                            className="mb-3"
                        />
                    </Col>
                )}
            </Row>
        </>
    );
};
